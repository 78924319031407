import { fg } from '@atlassian/jira-feature-gating';
import type {
	IssueId,
	CloudId,
	ProjectId,
	ProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { ISSUER_TYPE, ENTITY_TYPE, APP_INFO_MAP } from './constants.tsx';
import type { MessageEvent, AppType, ConversationType } from './types.tsx';

export const chatOpsMessageHandler = ({
	event,
	onSuccessCallback,
	onFailedCallback,
	requiredEventId,
}: {
	event: MessageEvent;
	onSuccessCallback?: () => void;
	onFailedCallback?: () => void;
	requiredEventId: string;
}) => {
	if (
		event.origin !== 'https://api-private.stg.atlassian.com' &&
		event.origin !== 'https://api-private.atlassian.com'
	)
		return;
	if (!event.data || !event.data.event_id || event.data.event_id !== requiredEventId) {
		return;
	}
	const eventData = event.data;

	if (eventData?.data?.success === 'true') {
		onSuccessCallback?.();
	} else if (eventData?.data?.success === 'false') {
		onFailedCallback?.();
	}
};

export const copyLinkToClipboard = ({
	linkToBeCopied,
	onSuccessCallback,
	onFailedCallback,
}: {
	linkToBeCopied: string;
	onSuccessCallback?: () => void;
	onFailedCallback?: () => void;
}) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	(fg('jfp-magma-undefined-navigator') ? globalThis.navigator.clipboard : navigator.clipboard)
		.writeText(linkToBeCopied || '')
		.then(() => {
			onSuccessCallback?.();
		})
		.catch(() => {
			onFailedCallback?.();
		});
};

export const commonUrl = (cloudId: CloudId) =>
	`/gateway/api/chatplatform/opsgenie/${cloudId}/api/internal-api/v3/chat`;

export const getAppStateForIssueAPIUrl = ({
	cloudId,
	issueId,
	projectId,
	type,
}: {
	cloudId: CloudId;
	issueId: IssueId;
	projectId: ProjectId;
	type: AppType;
}) =>
	`${commonUrl(cloudId)}/issue-state?issuerType=${ISSUER_TYPE}&issuerId=${issueId}&chatType=${
		APP_INFO_MAP[type].chatType
	}&entityType=${ENTITY_TYPE}&entityId=${projectId}&appType=${APP_INFO_MAP[type].appType}`;

export const createActionAPIUrl = ({ cloudId, type }: { cloudId: CloudId; type: AppType }) =>
	`${commonUrl(cloudId)}/conversations/${type}`;

export const removeActionAPIUrl = ({
	cloudId,
	connectionId,
	issueId,
	conversationType,
	type,
}: {
	cloudId: CloudId;
	connectionId: string;
	issueId: IssueId;
	conversationType: ConversationType;
	type: AppType;
}) =>
	`${commonUrl(
		cloudId,
	)}/conversation?connectionId=${connectionId}&issuerType=${ISSUER_TYPE}&issuerId=${issueId}&conversationType=${conversationType}&appType=${
		APP_INFO_MAP[type].appType
	}`;

export const getSiteSettingsUrl = () =>
	'/jira/settings/products/ops/jira-service-management-chatops-and-video-tools';

export const getProjectIMSettingsUrl = (projectKey?: ProjectKey) =>
	`/jira/servicedesk/projects/${projectKey ?? ''}/settings/incident-management?showIMSetting=true`;

export const getZoomUserAuthorise = ({
	cloudId,
	issueId,
	connectionId,
}: {
	cloudId: CloudId;
	issueId: IssueId;
	connectionId: string;
}) =>
	`${commonUrl(cloudId)}/authorize/zoom/agent?appType=${
		APP_INFO_MAP.zoom.appType
	}&openingURL=https://zoom.us/redirect&issueId=${issueId}&connectionId=${connectionId}`;

export const JIRA_SUPPORT_LINK = 'https://support.atlassian.com/contact/';
