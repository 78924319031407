// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import type { DispatchExternalAction, ExternalAction } from './external-actions.tsx';

type ModifyIssueViewPayload = {
	issueKey: string;
	externalAction: ExternalAction;
};

type IssueViewExternalActionCallback = (event: Event, payload: ModifyIssueViewPayload) => void;

type BindableEvents = {
	bind: (eventName: string, callback: IssueViewExternalActionCallback) => void;
};

const isBindableEvents = (Events: unknown): Events is BindableEvents => {
	if (!Events) return false;
	return typeof Events === 'object' && 'bind' in Events && typeof Events.bind === 'function';
};

type EventsModule = {
	bind: <T>(key: string, callback: (event: unknown, payload: T) => void) => void;
};

export const listenToExternalActionFromLegacy = (
	getDispatcherAndModifiedIssue: () => {
		dispatchExternalAction: DispatchExternalAction | null | undefined;
		modifiedIssueIssueKey: string | null;
	},
) => {
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge<EventsModule>({
		name: 'jira/util/events',
		wrmKeys: 'wrc!jira.webresources:jira-events',
	}).then((Events) => {
		if (isBindableEvents(Events)) {
			Events.bind<ModifyIssueViewPayload>('modify-issue-view-via-external-action', (e, payload) => {
				const { issueKey, externalAction } = payload;
				const { dispatchExternalAction, modifiedIssueIssueKey } = getDispatcherAndModifiedIssue();
				if (issueKey === modifiedIssueIssueKey && externalAction && dispatchExternalAction) {
					dispatchExternalAction(externalAction);
				}
			});
		}
	});
};
